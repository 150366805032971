import './index.scss';
import { FC } from 'react';
import { Col, Row } from 'rsuite';
import { If } from 'tsx-statements';
import FaIcon from '@components/FaIcon';
import useWindow from '@src/Tools/Hooks/useWindow';
import useLanguage from '@src/Tools/Hooks/useLanguage/useLanguage';
import { ReactComponent as KeepLogo } from '../../../Assets/icons/logo.svg';
import { ReactComponent as Github } from '../../../Assets/icons/Github.svg';
import { ReactComponent as Android } from '../../../Assets/icons/android.svg';
import { ReactComponent as GooglePlay } from '../../../Assets/icons/google-play.svg';
import { ReactComponent as FooterWaves } from '../../../Assets/icons/FooterWaves.svg';

const Footer: FC = () => {
	const { t } = useLanguage();
	const { isDesktop } = useWindow();

	return (
		<div className='footer-layout'>
			<If condition={isDesktop}>
				<FooterWaves className='waves' />
			</If>
			<Row>
				<Col xs={24} md={10} className='col-1'>
					<div className='col-1-div'>
						<KeepLogo className='keep-logo' />
						<div className='description'>
							<h5>{t('footer_1_title')}</h5>
							<p>{t('footer_1_des')}</p>

							<h5>{t('footer_2_title')}</h5>
							<p>{t('footer_2_des')}</p>

							<h5>{t('footer_3_title')}</h5>
							<p>{t('footer_3_des')}</p>
						</div>
					</div>
				</Col>

				<Col xs={24} md={4} className='col-2'>
					<div className='col-2-div'>
						<div className='link-wrapper'>
							<h4>{t('Features')}</h4>
							<div>
								<a href='./'>{t('About')}</a>
							</div>
							<div>
								<a href='./'>{t('Download')}</a>
							</div>
							<div>
								<a href='./'>{t('Documentation')}</a>
							</div>
							<div>
								<a href='./'>{t('Security')}</a>
							</div>
						</div>

						<div className='link-wrapper'>
							<h4 className='md:mt-16'>{t('Legal')}</h4>
							<div>
								<a href='./'>{t('Privacy Policy')}</a>
							</div>
							<div>
								<a href='./'>{t('Terms of Use')}</a>
							</div>
						</div>
					</div>
				</Col>

				<Col xs={24} md={10} className='col-3'>
					<div className='col-3-div'>
						<h4>{t('Download Links')}</h4>
						{downloadBtnsData?.map(b => (
							<a href={b?.link}>
								<div className='download-btn'>
									{b?.icon}
									{b?.name}
								</div>
							</a>
						))}
						<div className='social-networks'>
							<div className='footer-icons-wrapper'>
								<FaIcon fa='b-twitter' />
							</div>
							<div className='footer-icons-wrapper'>
								<FaIcon fa='b-discord' />
							</div>
							<div className='footer-icons-wrapper'>
								<FaIcon fa='b-linkedin-in' />
							</div>
							<div className='footer-icons-wrapper tele'>
								<FaIcon fa='s-paper-plane' />
							</div>
							<div className='footer-icons-wrapper'>
								<FaIcon fa='b-github' />
							</div>
						</div>
					</div>
				</Col>
			</Row>

			<div className='copyright'>Copyright © 2023 Xebra Design Studio. All rights reserved.</div>
		</div>
	);
};

export default Footer;

const downloadBtnsData = [
	{ name: 'Google Play', icon: <GooglePlay />, link: '' },
	{ name: 'GitHub', icon: <Github />, link: '' },
	{ name: 'Android', icon: <Android />, link: '' },
];
