export const dictionary: any = {
	hero_title: { FA: 'کیف پولی امن و با ثبات', EN: 'A secure and simple wallet' },
	hero_description: {
		FA: 'به کمک KEEP به سادگی به سیستم‌های مالی غیرمتمرکز در بستری کاملا امن دست خواهید یافت، تمامی دارایی‌های شما کاملا تحت کنترل شماست.',
		EN: 'You can easily access blockchains, and DeFi ecosystems in a fully secure and decentralized manner, ANY asset of yours is fully(and only!) under your control.',
	},

	'Download App': { FA: 'دانلود KEEP', EN: 'Download App' },
	'Watch Video': { FA: 'مشاهده ویدیو', EN: 'Watch Video' },

	'Safe & Secure': { FA: 'ایمن و مطمئن', EN: 'Safe & Secure' },
	'Easy to Use': { FA: 'کاربری آسان و ساده', EN: 'Easy to Use' },
	'Digital goods & services': { FA: 'کالاها و خدمات دیجیتال', EN: 'Digital goods & services' },

	'Payment services': { FA: 'خدمات پرداخت', EN: 'Payment services' },
	payment_services_opt1: { FA: 'پرداخت تافل، GRE و IELTS... ', EN: 'Payment of TOEFL, GRE, IELTS and ...' },
	payment_services_opt2: { FA: 'پرداخت اپلیکیشن فی دانشگاه‌ها', EN: 'Application payment per universities' },
	'Use DeFi': { FA: 'استفاده از DeFi', EN: 'Use DeFi' },
	use_deFi_opt1: { FA: 'سرمایه‌گذاری در صندوق‌ها (Vault) و اوراق (Treasury)و کسب سود', EN: 'Decentralized Exchange' },
	use_deFi_opt2: { FA: 'خرید و فروش توکن‌ها', EN: 'Decentralized Futures Leverage Trading' },
	use_deFi_opt3: { FA: 'معاملات اهرمی futures', EN: 'Yield Farming & Earning' },

	installation1_title: { FA: 'مطمئن ترین و ایمن ترین کیف پول', EN: 'The most trusted & Secure crypto wallet' },
	installation1_description: {
		EN: 'With the technical architecture used in the development of the KEEP, you can pay network fee in tokens. For example you can pay USDT for a transaction on Tron and Ethereum blockchain. You can transfer your tokens easily. In KEEP, you and only you can control your assets.',
		FA: 'به کمک تکنیک‌های فنی به کار گرفته شده در توسعه هسته KEEP،  می‌توانید کارمزد تراکنش را با کوین‌هایی همچون تتر پرداخت کنید و به سادگیِ تمام تراکنش بزنید! در کیپ دارایی‌های شما فقط و فقط تحت کنترل و اختیار شماست.',
	},

	'DOWNLOAD APK': { FA: 'دانلود APK', EN: 'DOWNLOAD APK' },
	'GET IT ON': { FA: 'دریافت از', EN: 'GET IT ON' },

	steps_title: { FA: 'در 3 مرحله ساده شروع کنید!', EN: 'Get Started in 3 simple steps' },
	'Create an account': { FA: 'ساخت حساب کاربری', EN: 'Create an account' },
	'setup/import secret phrases': { FA: 'تنظیم/وارد کردن عبارات مخفی', EN: ' setup/import secret phrases' },
	'boom! send/receive/buy/swap your assets!': {
		FA: 'بوووم! ارسال/دریافت/خرید دارایی های خود را مبادله کنید!',
		EN: 'boom! send/receive/buy/swap your assets!',
	},
	Step: { FA: 'مرحله', EN: 'Step' },
	'01': { FA: '1', EN: '01' },
	'02': { FA: '2', EN: '02' },
	'03': { FA: '3', EN: '03' },

	blockchains_title: { FA: 'بلاک چین هایی که پشتیبانی میشود', EN: 'Supported Blockchain' },
	blockchains_description: {
		FA: 'توسط بلاک چین های پیشرفته و امن تامین می شود',
		EN: 'KEEP is powered by advanced and secure blockchains',
	},

	'More Coming': {
		FA: 'به زودی',
		EN: `More
		 Coming`,
	},
	Coming: {
		FA: 'به زودی',
		EN: 'Coming',
	},

	installation2_title: {
		FA: 'امتحانش کن!',
		EN: 'Try it now!',
	},
	installation2_description: {
		FA: 'همین الان کیپ رو نصب کن و دوستانت رو هم دعوت کن.',
		EN: 'Try KEEP right now and invite your friends to join you.',
	},

	footer_1_title: {
		FA: 'Security',
		EN: 'Security',
	},
	footer_1_des: {
		FA: 'امنیت کیپ، با تکنولوژی‌های رمزنگاری و مکانیزم‌های اجماع تامین شده است.',
		EN: 'KEEP is secured by cryptographic algorithms and consensus mechanisms of blockchains.',
	},
	footer_2_title: {
		FA: 'متن باز OpenSource',
		EN: 'Open Source',
	},
	footer_2_des: {
		FA: 'با هدف ترویج فرهنگ متن‌باز، کیپ به صورت متن باز توسعه پیدا می‌کند.',
		EN: 'We believe in the open source software and as long as the security is not compromised, we plan to keep it open source',
	},
	footer_3_title: {
		FA: 'عدم تمرکز',
		EN: 'Decentralization',
	},
	footer_3_des: {
		FA: 'کیپ از حامیان فرهنگ عدم تمرکز است و با کیپ، کنترل کامل دارایی‌های شما در اختیار شماست. حتی تیم کیپ نیز کنترلی بر دارایی‌های شما ندارد.',
		EN: 'We believe in decentralization and self-custody. With KEEP, you keep self-custody of your assets.',
	},
	Features: {
		FA: 'دسترسی سریع',
		EN: 'Features',
	},
	About: {
		FA: 'درباره ما',
		EN: 'About',
	},
	Download: {
		FA: 'دانلودها',
		EN: 'Download',
	},
	Documentation: {
		FA: 'داکیومنت ها',
		EN: 'Documentation',
	},
	Security: {
		FA: 'امنیت',
		EN: 'Security',
	},
	Legal: {
		FA: 'مجوزها',
		EN: 'Legal',
	},
	'Privacy Policy': {
		FA: 'Privacy Policy',
		EN: 'Privacy Policy',
	},
	'Terms of Use': {
		FA: 'Terms of Use',
		EN: 'Terms of Use',
	},
	'Download Links': {
		FA: 'لینک های دانلود',
		EN: 'Download Links',
	},
};
