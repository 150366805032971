import './index.scss';
import { useState } from 'react';
import FaIcon from '@components/FaIcon';
import useStore from '@tools/Store/useStore';
import useWindow from '@tools/Hooks/useWindow';
import { Navbar, Drawer, Toggle } from 'rsuite';
import { Divide as Hamburger } from 'hamburger-react';
import { classNames, classes } from '@tools/Utils/React';
import { ReactComponent as KeepLogo } from '@assets/icons/logo.svg';
import { ReactComponent as ChangeLang } from '@assets/icons/lang.svg';
import CustomDropdown from '@components/CustomDropDown/CustomDropDown';
import { setLanguage, setLightMode } from '@tools/Store/actions/DashboardActions';
import useLanguage, { supportedLangs } from '@tools/Hooks/useLanguage/useLanguage';

const NavBar = () => {
	const { lang } = useLanguage();
	const { dispatch } = useStore();
	const { isMobile } = useWindow();
	const [isOpen, setOpen] = useState(false);

	const mapLang: { [key: string]: string } = {};

	for (const { name, shortName } of supportedLangs) mapLang[shortName] = name;

	return (
		<div {...classes('navbar-layout')}>
			<Navbar>
				<div className='navbar-brand'>
					<KeepLogo className='keep-logo' />
				</div>

				<div className='toggles-wrapper'>
					<CustomDropdown
						className='toggle-language'
						popoverClassName='select-lang-popover'
						title={
							<div className='toggle-title'>
								<ChangeLang className='change-lang' />
								{mapLang[lang]}
							</div>
						}>
						{supportedLangs?.map(l => (
							<div
								onClick={() => dispatch(setLanguage(l?.shortName))}
								className={classNames('toggle-option', { selected: l?.shortName === lang })}>
								<div className='option-name-wrapper'>
									<div className='option-icon'>{l?.shortName}</div>
									<div className='option-text'>{l?.name}</div>
								</div>
								<FaIcon fa='r-check' />
							</div>
						))}
					</CustomDropdown>

					<Toggle
						onChange={v => dispatch(setLightMode(v))}
						unCheckedChildren={<FaIcon fa='r-moon' />}
						checkedChildren={<FaIcon fa='r-sun-bright' />}
					/>
				</div>
			</Navbar>

			<Drawer open={isOpen && isMobile} onClose={() => setOpen(false)}>
				<div className='header'>
					<Hamburger rounded size={26} distance='sm' color='#466CCE' toggled={isOpen} toggle={setOpen} />
				</div>
			</Drawer>
		</div>
	);
};

export default NavBar;
