import useDashboard from '../useDashboard';
import { dictionary } from '@src/Data/Dictionary';

export const supportedLangs: any[] = [
	{ name: 'English', shortName: 'EN' },
	{ name: 'فارسی', shortName: 'FA' },
];

const useLanguage = () => {
	const { lang } = useDashboard();

	const isRtl = ['FA', 'AR']?.includes(lang);

	const t = (word: any) => dictionary[word][lang];

	return { isRtl, lang, t };
};

export default useLanguage;
