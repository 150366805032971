import { FC, useRef } from 'react';
import './CustomDropDown.scss';
import FaIcon from '@components/FaIcon';
import { Else, If } from 'tsx-statements';
import { classNames } from '@tools/Utils/React';
import { Dropdown, Popover, Whisper } from 'rsuite';

const CustomDropdown: FC<{
	title?: any;
	onSelect?: any;
	selected?: any;
	hasIcon?: boolean;
	className?: string;
	popoverClassName?: string;
	items?: { label: any; value: string }[];
}> = ({ children, onSelect, selected, className, items = [], hasIcon = true, title = 'select', popoverClassName }) => {
	const refWhisper = useRef<any>();

	const popover = ({ className, left, top, onClose }: any, ref: any) => (
		<Popover style={{ left, top }} className={`${popoverClassName} ${className}`} ref={ref}>
			<Dropdown.Menu
				onSelect={(eventKey: any, event: any) => {
					onSelect({ value: eventKey, label: event?.target?.dataset?.label });
					onClose();
				}}>
				<If condition={items?.length > 0}>
					{items?.map((item: any, i: number) => (
						<Dropdown.Item
							key={i}
							eventKey={item?.value}
							children={item?.label}
							data-label={item?.label}
							className={classNames({ selected: selected?.value === item?.value })}
						/>
					))}
					<Else>
						<div onClick={() => refWhisper?.current?.close()}>{children}</div>
					</Else>
				</If>
			</Dropdown.Menu>
		</Popover>
	);

	return (
		<div className={`custom-dropdown ${className}`}>
			<Whisper ref={refWhisper} placement='bottom' trigger='click' speaker={popover} className='custom-popover'>
				<div className='drop-down-open-btn'>
					{title}
					<If condition={hasIcon}>
						<FaIcon fa='r-angle-down' />
					</If>
				</div>
			</Whisper>
		</div>
	);
};

export default CustomDropdown;
