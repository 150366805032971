import { createReducer } from '@reduxjs/toolkit';
import { setLoading, setLightMode, setUploadProgress, setLanguage } from '../actions/DashboardActions';

type InitStateType = { isLoading: boolean; uploadProgress: number; isLightMode: boolean; lang: 'FA' | 'EN' };

const initState: InitStateType = { isLoading: false, uploadProgress: 0, isLightMode: false, lang: 'EN' };

const DashboardReducer = createReducer(initState, {
	//* upload progress
	[setUploadProgress.type]: (state, { payload }) => ({ ...state, uploadProgress: payload }),

	//* loading
	[setLoading.type]: (state, { payload }) => ({ ...state, isLoading: payload }),

	// * is Light mode theme
	[setLightMode.type]: (state, { payload }) => ({ ...state, isLightMode: payload }),

	// * Language
	[setLanguage.type]: (state, { payload }) => ({ ...state, lang: payload }),
});

export default DashboardReducer;
