import './index.scss';
import Navbar from './Navbar';
import Footer from './Footer';
import { FC, useEffect } from 'react';
import useDashboard from '@tools/Hooks/useDashboard';
import usePersistedState from '@tools/Hooks/usePersistedState';
import useLanguage, { supportedLangs } from '@tools/Hooks/useLanguage/useLanguage';
import ShadowCircle from '@components/ShadowCircle/ShadowCircle';

const Layouts: FC = ({ children }) => {
	const { isRtl, lang: curLang } = useLanguage();
	const { isLightMode } = useDashboard();
	const [themeMode, setThemeMode] = usePersistedState('THEME_MODE', 'dark');
	const classes = document?.body?.classList;

	useEffect(() => {
		setThemeMode(isLightMode ? 'light' : 'dark');
	}, [isLightMode]);

	useEffect(() => {
		const themes = ['dark', 'light'];
		themes?.forEach(i => classes?.remove(`theme-${i}`));
		classes?.add(`theme-${themeMode}`);
	}, [themeMode]);

	useEffect(() => {
		const langs = ['rtl', 'ltr'];
		const lang = isRtl ? 'rtl' : 'ltr';
		langs?.forEach(i => classes?.remove(`lang-${i}`));
		classes?.add(`lang-${lang}`);
	}, [isRtl]);

	useEffect(() => {
		supportedLangs?.forEach(l => classes?.remove(`lang-${l.shortName}`));
		classes?.add(`lang-${curLang}`);
	}, [curLang]);

	return (
		<div className='root-layout'>
			<ShadowCircle className='root-first-circle' />
			<Navbar />
			<div className='root-children'>{children}</div>
			<Footer />
		</div>
	);
};

export default Layouts;
